import React from "react";
const { useRef, useState, useEffect } = React;
import { Base, Text } from "../../styles";
import styled from "styled-components";
const Audio = styled("audio")`
  opacity: 0;
`;
let SVG = styled("svg")`
  height: 250px;
  width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.15s;
`;
const Circle = styled(Base)`
  fill: none;
  stroke: #fff;
  stroke-width: 1.5;
  transition: ${(props) => props.transition};
  stroke-dasharray: ${(props) => props.strokeDasharray};
`;
const EngineSoundBtn = styled(Base)`
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 30px;
  grid-gap: 0px;
  color: white;
  margin: 18px auto;
  position: relative;
  transition: 0.5s, transform 0.3s;
  user-select: none;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 100%;
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
  }
  &:hover :after {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &:active {
    transform: scale(0.95);
    background-color: #E60012;
  }
`;
export default ({ audioName }) => {
  const audioRef = useRef(null);
  const circleRef = useRef(null);
  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const [intervalID, setIntervalID] = useState(null);
  const [strokeDasharray, setStrokeDasharray] = useState("315 315");
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(EngineSoundBtn, {
    onClick: () => {
      let playAudio = !isAudioPlaying;
      setAudioPlaying(!isAudioPlaying);
      if (playAudio) {
        audioRef.current.play();
      } else {
        clearInterval(intervalID);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setStrokeDasharray("315 315");
      }
    },
    backgroundColor: isAudioPlaying ? "white" : "#E60012"
  }, /* @__PURE__ */ React.createElement(SVG, {
    viewBox: "0 0 130 130",
    id: "circle"
  }, /* @__PURE__ */ React.createElement(Circle, {
    as: "circle",
    ref: circleRef,
    transform: "rotate(-90 70 70)",
    transition: isAudioPlaying ? "0.05s" : "0.3s",
    strokeDasharray,
    cx: "75",
    cy: "65",
    r: "50"
  })), /* @__PURE__ */ React.createElement(Text, {
    variant: "small",
    color: isAudioPlaying ? "#E60012" : "white",
    zIndex: 1
  }, "PUSH TO"), /* @__PURE__ */ React.createElement(Text, {
    variant: "large-bold",
    color: isAudioPlaying ? "#E60012" : "white",
    zIndex: 1
  }, isAudioPlaying ? "STOP" : "START")), /* @__PURE__ */ React.createElement(Audio, {
    preload: "metadata",
    ref: audioRef,
    src: "/audio/" + audioName,
    controls: true,
    onEnded: () => {
      setAudioPlaying(false);
      clearInterval(intervalID);
    },
    onPlay: () => {
      const audioDur = audioRef.current.duration;
      setIntervalID(setInterval(function() {
        const perc = audioRef.current.currentTime / audioDur;
        setStrokeDasharray(315 * perc + " 315");
      }, 50));
    }
  }));
};

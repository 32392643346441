import React from "react";
const { useRef, useState, useEffect } = React;
import { Base } from "../../styles";
import styled from "styled-components";
import { gsap } from "gsap";
const Container = styled(Base)``;
export default () => {
  const tl = useRef(null);
  const arrow1 = useRef(null);
  const arrow2 = useRef(null);
  const arrow3 = useRef(null);
  useEffect(() => {
    tl.current = gsap.timeline({ repeat: -1 });
    tl.current.from([arrow1.current, arrow2.current, arrow3.current], {
      autoAlpha: 0,
      duration: 0.5,
      stagger: 0.15
    }).to([arrow1.current, arrow2.current, arrow3.current], {
      autoAlpha: 0,
      duration: 0.5,
      stagger: 0.15
    }, "-=0.3");
  }, [arrow1, arrow2, arrow3]);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement("svg", {
    id: "Layer_1",
    "data-name": "Layer 1",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 36.61 103.98",
    style: {
      position: "absolute",
      bottom: "50%",
      transform: "translateY(50%)",
      right: "20px",
      width: "30px"
    }
  }, /* @__PURE__ */ React.createElement("path", {
    ref: arrow1,
    d: "M37.53,102.3a1.23,1.23,0,0,1-.79-.28l-12-9.69a1.27,1.27,0,1,1,1.6-2l11.17,9,11.18-9a1.27,1.27,0,0,1,1.6,2l-12,9.69A1.24,1.24,0,0,1,37.53,102.3Z",
    transform: "translate(-19.23 -21.47)"
  }), /* @__PURE__ */ React.createElement("path", {
    ref: arrow2,
    d: "M37.53,113.88a1.24,1.24,0,0,1-.79-.29l-12-9.69a1.27,1.27,0,1,1,1.6-2l11.17,9,11.18-9a1.27,1.27,0,1,1,1.6,2l-12,9.69A1.25,1.25,0,0,1,37.53,113.88Z",
    transform: "translate(-19.23 -21.47)"
  }), /* @__PURE__ */ React.createElement("path", {
    ref: arrow3,
    d: "M37.53,125.45a1.23,1.23,0,0,1-.79-.28l-12-9.69a1.27,1.27,0,1,1,1.6-2l11.17,9,11.18-9a1.27,1.27,0,1,1,1.6,2l-12,9.69A1.24,1.24,0,0,1,37.53,125.45Z",
    transform: "translate(-19.23 -21.47)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M37.53,80a18.32,18.32,0,0,1-18.3-18.3V39.77a18.31,18.31,0,0,1,36.61,0v21.9A18.32,18.32,0,0,1,37.53,80Zm0-55.95A15.77,15.77,0,0,0,21.78,39.77v21.9a15.76,15.76,0,1,0,31.51,0V39.77A15.78,15.78,0,0,0,37.53,24Z",
    transform: "translate(-19.23 -21.47)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M37.53,43.65h0A2.52,2.52,0,0,1,35,41.12v-6a2.52,2.52,0,0,1,2.52-2.52h0a2.52,2.52,0,0,1,2.53,2.52v6A2.53,2.53,0,0,1,37.53,43.65Z",
    transform: "translate(-19.23 -21.47)"
  })));
};

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import Page from "../components/Page";
import EngineDetail from "../components/EngineDetail";
import Footer from "../components/Footer";
export default (props) => {
  return /* @__PURE__ */ React.createElement(Page, __spreadProps(__spreadValues({}, props), {
    title: `${props.pageContext.engineDetails.engineName} | Kawasaki Virtual Experience`,
    metaDescription: props.pageContext.engineDetails.metaDescription,
    fixed: true
  }), /* @__PURE__ */ React.createElement(EngineDetail, __spreadValues({}, props.pageContext.engineDetails)), /* @__PURE__ */ React.createElement(Footer, null));
};

import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Base, BaseLink, Text, StyledGatsbyImage } from "../../styles";
import styled from "styled-components";
import Stat from "../../components/Stat";
import SeriesCTA from "../SeriesCTA";
import EngineSoundBtn from "./EngineNoiseBtn";
import ScrollIcon from "./ScrollIcon";
import ButtonCard from "../ButtonCard";
import ContentGutter from "../ContentGutter";
import SeriesCTAGrid from "../SeriesCTA/SeriesCTAGrid";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Img from "gatsby-image";
import CarouselNav from "../CarouselNav";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import SVGFX850V from "../../images/engines/KMC_LINEART_FX850V.svg";
import SVGFR730V from "../../images/engines/KMC_LINEART_FR730V.svg";
import SVGFT730VEFI from "../../images/engines/KMC_LINEART_FT730V_EFI.svg";
import SVGFX1000VEFI from "../../images/engines/KMC_LINEART_FX1000V_EFI.svg";
import SVGFX820VEVO from "../../images/engines/KMC_LINEART_FX820V_EVO.svg";
const svgImages = {
  fx850v: SVGFX850V,
  fr730v: SVGFR730V,
  "ft730v-efi": SVGFT730VEFI,
  "fx1000v-efi": SVGFX1000VEFI,
  "fx820v-evo": SVGFX820VEVO
};
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
export default (props) => {
  const [hasEnded, setHasEnded] = useState(false);
  const seriesCTAImageQuery = useStaticQuery(graphql`
    query {
      testingimage: file(
        relativePath: {
          eq: "testing/Kawasaki_2021_JH_5519_Enviromental Chamber Interior_PSWork.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 1.9
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      engines: allEnginesJson(sort: {order: ASC, fields: order}) {
        nodes {
          ...EnginesFragment
        }
      }
    }
  `);
  const engines = seriesCTAImageQuery.engines;
  const testingImage = getImage(seriesCTAImageQuery.testingimage);
  const svgImage = svgImages[props.slug];
  const [selectedEngine, setSelectedEngine] = useState(engines.nodes.find((item) => item.engineName == props.engineName));
  const BackLink = styled(BaseLink)`
    color: black;
    text-decoration: none;
    margin-bottom: 7vh;

    &:hover {
      text-decoration: underline;
    }
  `;
  const RightArrow = styled(Base)`
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent black transparent transparent;
  `;
  const Background = styled(Base)`
    min-height: 100vh;
    background-color: #000;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `;
  const StatsWrapper = styled(Base)`
    display: grid;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 64px);
    max-width: 1024px;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 32px 0;
  `;
  const Video = styled("video")`
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
  `;
  const TextSection = styled(Base)`
    position: fixed;
    width: 35%;
    top: 50%;
    left: 12%;
    transform: translateY(-50%);
    padding: 20px;
    display: grid;
    align-content: center;
    grid-gap: 8px;
    box-sizing: border-box;
    &.right {
      right: 12%;
      left: unset;
    }
    @media (max-width: 1000px) {
      bottom: 30px;
      left: 0;
      top: unset;
      transform: unset;
      right: unset;
      width: 100%;
    }
  `;
  const Divider = styled(Base)`
    height: 8px;
    background-color: #E60012;
  `;
  const ContentWrapper = styled("div")`
    min-height: 100%;
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0;
    grid-gap: 32px;
    text-align: center;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
  `;
  const data = {
    headline: "Rigorous Testing",
    copy: "We relentlessly test our engines so what's in your mower can help you complete your job.",
    link: {
      url: "/testing",
      text: "See How"
    }
  };
  const main = useRef();
  const container = useRef();
  const bigEnginePanel = useRef();
  const statsPanel = useRef();
  const videoPanel = useRef();
  const engineNoisePanel = useRef();
  const testingPanel = useRef();
  const engineBackLink = useRef();
  const engineAttribute = useRef();
  const engineName = useRef();
  const engineDesc = useRef();
  const engineImg = useRef();
  const engineStats1 = useRef();
  const engineStats2 = useRef();
  const testingBg = useRef();
  const testingScreen = useRef();
  const testingCopy = useRef();
  const hotspotVideo = useRef(null);
  const tl = useRef(null);
  const q = gsap.utils.selector(container);
  useLayoutEffect(() => {
    const totalScrollHeight = window.innerHeight * 7;
    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: "#container",
        pin: true,
        start: "top top",
        end: "+=" + totalScrollHeight,
        scrub: 1,
        snap: {
          snapTo: "labels",
          duration: { min: 0.2, max: 1 },
          delay: 0,
          ease: "power3.in"
        }
      }
    });
    tl.current.from([
      engineBackLink.current,
      engineAttribute.current,
      engineName.current,
      engineImg.current
    ], {
      y: 200,
      autoAlpha: 0,
      duration: 1,
      stagger: 0.6
    }).addLabel("intro part1").to([engineBackLink.current, engineAttribute.current, engineName.current], { y: -100, autoAlpha: 0, duration: 1, stagger: 0.2 }).from(engineDesc.current, { y: 100, autoAlpha: 0, duration: 1 }).addLabel("intro part2").to(engineDesc.current, { y: -100, autoAlpha: 0, duration: 1 }).to(engineImg.current, {
      y: -380,
      scale: 0.9,
      duration: 1
    });
    tl.current.from(statsPanel.current, { autoAlpha: 0 }).from(engineStats1.current, { y: 100, autoAlpha: 0 }).set(bigEnginePanel.current, { autoAlpha: 0 }).addLabel("stats part1").to(engineStats1.current, { y: -100, autoAlpha: 0 }).from(engineStats2.current, { y: 100, autoAlpha: 0 }).addLabel("stats part2");
    tl.current.from(videoPanel.current, { autoAlpha: 0 }).set(statsPanel.current, { autoAlpha: 0 });
    const hotspotDuration = 10;
    props.videoHotspots.map((item, index) => {
      tl.current.addLabel("hotspot " + index, "+=" + item.position * hotspotDuration);
    });
    tl.current.fromTo(hotspotVideo.current, {
      currentTime: 0
    }, {
      currentTime: props.videoDuration,
      duration: hotspotDuration
    });
    props.videoHotspots.map((item, index) => {
      tl.current.from(q(`#hotspot${index}`), { autoAlpha: 0, duration: 0.5 }, `hotspot ${index}-=0.5`);
      tl.current.from(q(`#hotspot${index}`), {
        x: index % 2 == 0 ? 300 : -300,
        duration: 1.5,
        ease: "sine.inOut"
      }, `hotspot ${index}-=1.5`);
      tl.current.to(q(`#hotspot${index}`), { autoAlpha: 0, duration: 0.5, delay: 0.5 }, `hotspot ${index}`);
      tl.current.to(q(`#hotspot${index}`), {
        x: index % 2 == 0 ? -300 : 300,
        duration: 1.5,
        ease: "sine.inOut"
      }, `hotspot ${index}`);
      if (window.innerWidth > 1e3) {
        tl.current.to(q(`video`), {
          scale: 1.3,
          x: index % 2 == 0 ? 350 : -350,
          duration: 1,
          ease: "sine.inOut"
        }, `hotspot ${index}-=1`);
      } else {
        tl.current.to(q(`video`), {
          scale: 1.3,
          y: index % 2 == 0 ? -100 : -150,
          duration: 1,
          ease: "sine.inOut"
        }, `hotspot ${index}-=1`);
      }
    });
    tl.current.from(engineNoisePanel.current, { autoAlpha: 0 }).set(videoPanel.current, { autoAlpha: 0 }).addLabel("Engine Noise").to(engineNoisePanel.current, { yPercent: -100, duration: 1 }, "+=0.3").addLabel("Engine Noise end");
    tl.current.from(testingPanel.current, { autoAlpha: 0, duration: 0.5 }, "-=1").set(engineNoisePanel.current, { autoAlpha: 0 }).addLabel("Testing CTA");
    gsap.to(window, {
      duration: 4,
      scrollTo: {
        y: tl.current.labels["intro part1"] / tl.current.duration() * totalScrollHeight,
        autoKill: true
      },
      ease: "sine.out"
    });
    console.log(tl.current.labels);
    return () => {
      tl.current.kill();
    };
  }, [
    container,
    engineBackLink,
    bigEnginePanel,
    statsPanel,
    videoPanel,
    engineNoisePanel,
    testingPanel,
    hotspotVideo
  ]);
  useEffect(() => {
    let firstTouch = true;
    window.addEventListener("touchstart", function() {
      if (firstTouch) {
        firstTouch = false;
        hotspotVideo.current.play();
        hotspotVideo.current.pause();
      }
    });
  }, [hotspotVideo]);
  return /* @__PURE__ */ React.createElement(Base, {
    as: "main",
    id: "main",
    ref: main
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    height: [
      "calc(100vh - 58px)",
      "calc(100vh - 58px)",
      "calc(100vh - 104px)"
    ],
    ref: container,
    id: "container",
    position: "relative",
    paddingTop: ["58px", "58px", "104px"]
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "section",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    className: "container",
    backgroundColor: "#fff",
    position: "absolute",
    top: ["58px", "58px", "104px"],
    left: "0",
    width: "100%",
    height: [
      "calc(100vh - 58px)",
      "calc(100vh - 58px)",
      "calc(100vh - 104px)"
    ],
    overflow: "hidden",
    ref: bigEnginePanel
  }, /* @__PURE__ */ React.createElement(BackLink, {
    color: "black",
    textTransform: "uppercase",
    display: "inline-block",
    to: "/engines/",
    mt: "34px",
    className: "staggerFade",
    ref: engineBackLink
  }, /* @__PURE__ */ React.createElement(RightArrow, {
    mr: "8px",
    borderWidth: ["4px 6px 4px", "6px 8px 6px 0"]
  }), /* @__PURE__ */ React.createElement(Text, {
    variant: ["xxx-small", "x-small"]
  }, "BACK TO ALL ENGINES")), /* @__PURE__ */ React.createElement(Text, {
    variant: ["small-bold", "medium-bold"],
    color: "red",
    id: "engineAttribute",
    className: "staggerFade",
    ref: engineAttribute
  }, props.engineDetailEyebrow), /* @__PURE__ */ React.createElement(Text, {
    as: "div",
    variant: ["xx-large", "xx-large", "xxx-large", "ultra"],
    zIndex: 2,
    className: "staggerFade",
    ref: engineName
  }, props.engineName), /* @__PURE__ */ React.createElement(Text, {
    as: "div",
    variant: ["small", "small", "medium", "large"],
    textAlign: "center",
    position: "absolute",
    top: ["54px"],
    maxWidth: ["587px", "587px", "587px", "1110px"],
    width: "calc(100% - 60px)",
    id: "engineDesc",
    ref: engineDesc
  }, props.engineDetailDescription), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    id: "engineImg",
    mt: ["-30px", "-30px", "-30px", "-200px"],
    maxWidth: ["1200px"],
    maxHeight: ["1200px"],
    width: "calc(100% - 24px)",
    height: ["100vw"],
    className: "staggerFade",
    ref: engineImg
  }, /* @__PURE__ */ React.createElement(Img, {
    fluid: props.bigEngineImage.fluid,
    style: {
      paddingLeft: "12px",
      paddingRight: "12px",
      height: "100%"
    },
    imgStyle: {
      position: "static",
      objectFit: "contain"
    }
  })), /* @__PURE__ */ React.createElement(ScrollIcon, null)), /* @__PURE__ */ React.createElement(Base, {
    ref: statsPanel,
    position: "absolute",
    top: ["58px", "58px", "104px"],
    left: "0",
    width: "100%",
    height: [
      "calc(100vh - 58px)",
      "calc(100vh - 58px)",
      "calc(100vh - 104px)"
    ],
    overflow: "hidden"
  }, /* @__PURE__ */ React.createElement(Background, {
    className: "stats",
    as: "div"
  }, /* @__PURE__ */ React.createElement(Base, {
    as: svgImage,
    position: "absolute",
    top: "50%",
    left: "50%",
    style: { transform: `translate(-50%, -50%)` },
    opacity: "0.25"
  })), /* @__PURE__ */ React.createElement(StatsWrapper, {
    id: "stats1",
    gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
    gridGap: ["40px", "32px"],
    ref: engineStats1
  }, props.stats.slice(0, 3).map((stat) => /* @__PURE__ */ React.createElement(Stat, {
    key: stat.stat,
    stat: stat.stat,
    label: stat.label,
    variant: ["xx-large", "xx-large", "xxx-large"]
  }))), /* @__PURE__ */ React.createElement(StatsWrapper, {
    id: "stats2",
    gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
    gridGap: ["40px", "32px"],
    ref: engineStats2
  }, props.stats.slice(3).map((stat) => /* @__PURE__ */ React.createElement(Stat, {
    key: stat.stat,
    stat: stat.stat,
    label: stat.label,
    variant: ["medium-bold", "medium-bold", "large-bold"]
  })))), /* @__PURE__ */ React.createElement(Base, {
    ref: videoPanel,
    position: "absolute",
    top: ["58px", "58px", "104px"],
    left: "0",
    width: "100%",
    height: [
      "calc(100vh - 58px)",
      "calc(100vh - 58px)",
      "calc(100vh - 104px)"
    ],
    overflow: "hidden"
  }, /* @__PURE__ */ React.createElement(Video, {
    src: "/videos/" + props.videoName,
    playsInline: true,
    muted: true,
    ref: hotspotVideo,
    preload: "auto"
  }), props.videoHotspots.map((item, index) => {
    return /* @__PURE__ */ React.createElement(TextSection, {
      as: "div",
      className: index % 2 == 0 ? "left" : "right",
      id: "hotspot" + index
    }, /* @__PURE__ */ React.createElement(Text, {
      variant: ["small-bold", "medium-bold"],
      color: "red",
      textTransform: "uppercase"
    }, item.title), /* @__PURE__ */ React.createElement(Divider, {
      width: ["100%", "50px"]
    }), /* @__PURE__ */ React.createElement(Text, {
      variant: ["x-small"],
      maxWidth: "75ch"
    }, item.description));
  })), /* @__PURE__ */ React.createElement(Base, {
    position: "absolute",
    top: ["58px", "58px", "104px"],
    left: "0",
    width: "100%",
    height: [
      "calc(100vh - 58px)",
      "calc(100vh - 58px)",
      "calc(100vh - 104px)"
    ],
    overflow: "hidden",
    ref: engineNoisePanel
  }, /* @__PURE__ */ React.createElement(Background, {
    as: "div",
    className: "engineNoise",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  }, /* @__PURE__ */ React.createElement(Base, {
    as: svgImage,
    position: "absolute",
    top: "50%",
    left: "50%",
    style: { transform: `translate(-50%, -50%)` },
    zIndex: "1",
    opacity: "0.25"
  }), /* @__PURE__ */ React.createElement(ContentWrapper, null, /* @__PURE__ */ React.createElement(Text, {
    variant: "x-large-bold",
    color: "red"
  }, "HEAR THE", " ", /* @__PURE__ */ React.createElement(Text, {
    variant: "x-large-bold",
    color: "white"
  }, props.engineName), " ", /* @__PURE__ */ React.createElement("br", null), "IN ACTION"), /* @__PURE__ */ React.createElement(Text, {
    variant: "small",
    color: "white",
    maxWidth: "45ch"
  }, "Experience the difference between the sound of low and high idle, and when the blade is engaged."), /* @__PURE__ */ React.createElement(EngineSoundBtn, {
    audioName: props.audioName
  })))), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: "grid",
    position: "absolute",
    top: ["58px", "58px", "104px"],
    left: "0",
    width: "100%",
    height: [
      "calc(100vh - 58px)",
      "calc(100vh - 58px)",
      "calc(100vh - 104px)"
    ],
    zIndex: -1,
    overflow: "hidden",
    ref: testingPanel
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    width: "100%",
    height: "100%",
    ref: testingBg,
    position: "absolute",
    top: "0",
    left: "0"
  }, /* @__PURE__ */ React.createElement(StyledGatsbyImage, {
    width: "100%",
    height: "100%",
    alt: "",
    image: testingImage
  })), /* @__PURE__ */ React.createElement(Base, {
    ref: testingScreen,
    backgroundColor: "black",
    display: "block",
    gridArea: ["1/1"]
  }), /* @__PURE__ */ React.createElement(Base, {
    ref: testingCopy,
    gridArea: "1/1",
    position: "relative",
    placeItems: "center",
    display: "grid",
    style: {
      backgroundImage: "linear-gradient(90deg, rgba(0,0,0,0.75), transparent)"
    }
  }, /* @__PURE__ */ React.createElement(ContentGutter, null, /* @__PURE__ */ React.createElement(SeriesCTAGrid, {
    copySide: "left"
  }, /* @__PURE__ */ React.createElement(ButtonCard, {
    headline: data.headline,
    copy: data.copy,
    link: data.link
  })))))), /* @__PURE__ */ React.createElement(SeriesCTA, {
    headline: props.seriesCTA.headline,
    copy: props.seriesCTA.copy,
    link: {
      url: props.seriesCTA.link.url,
      text: props.seriesCTA.link.text
    },
    copySide: props.seriesCTA.copySide,
    backgroundImage: getImage(props.preFooterImage)
  }), /* @__PURE__ */ React.createElement(CarouselNav, {
    engines: engines.nodes,
    selectedEngine,
    onNext: (engine) => {
      setSelectedEngine(engine);
      navigate("/engines/" + engine.slug, {
        state: { engine, prevPath: location.pathname }
      });
    },
    onPrev: (engine) => {
      setSelectedEngine(engine);
      navigate("/engines/" + engine.slug, {
        state: { engine, prevPath: location.pathname }
      });
    },
    loop: false
  }));
};

import React from "react";
import { Text } from "../styles";
export default ({
  stat,
  label,
  variant
}) => {
  return /* @__PURE__ */ React.createElement(Text, {
    as: "div",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%"
  }, /* @__PURE__ */ React.createElement(Text, {
    variant,
    color: "white",
    textAlign: "center",
    dangerouslySetInnerHTML: { __html: stat.replace("\n", "<br />") },
    lineHeight: "1.2em"
  }), /* @__PURE__ */ React.createElement(Text, {
    variant: ["small-bold", "medium-bold"],
    color: "red",
    mt: ["8px", null, "16px"],
    textTransform: "uppercase"
  }, label));
};
